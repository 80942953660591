.navbar .dropdown-menu .link-list-wrapper ul li a.list-item.active span {
  color: $link-color;
}

.text-primary {
  color: $link-color !important;
}

.chip {
  &.chip-primary {
    color: $primary-text;

    & > .chip-label {
      color: $primary-text;
    }

    &:hover {
      background-color: $primary;
      border-color: $primary;

      & > .chip-label {
        color: $primary-text;
      }
    }
  }
}

.btn-outline-primary {
  color: $primary-text;

  &:hover {
    color: darken($primary-text, 10%);
  }
}

.icon-primary {
  fill: $link-color !important;
}

a.read-more .icon {
  fill: $link-color;
}

.card .card-body .category-top a.category {
  color: $link-color;
}

.nav-tabs {
  .nav-link:hover {
    color: $link-color;
  }

  .nav-link.active,
  .form-control:disabled + label.nav-link,
  .form-control[readonly] + label.nav-link,
  .nav-item.show .nav-link {
    border-bottom-color: $link-color;
    color: $link-color;
  }
}

.pagination .page-item {
  .page-link[aria-current] {
    border: 1px solid $link-color;
    color: $link-color;
  }

  .page-link:hover {
    color: $link-color;
  }
}

.search-view {
  .text-primary {
    .react-select__option--is-focused {
      color: $tertiary-text;
    }
  }
}

.block .searchSections:not(.button) .searchContainer .searchbar button svg {
  fill: $link-color;
}

.bandi-search,
.event-search {
  .bg-primary {
    .search-container .filter-wrapper {
      &.select-filter {
        .react-select__control {
          .react-select__placeholder {
            color: $primary-text !important;
          }

          .react-select__single-value {
            color: $primary-text !important;
          }
        }

        .react-select__menu .react-select__option--is-focused {
          color: $primary-text;
        }
      }
      .react-select__indicators svg {
        fill: $primary-text !important;
      }

      &.date-filter .DateRangePickerInput {
        .DateInput_input {
          color: $primary-text !important;
        }

        .DateRangePickerInput_arrow .DateRangePickerInput_arrow_svg {
          fill: $primary-text;
        }

        .DateRangePickerInput_clearDates svg {
          fill: $primary-text;
        }
      }
    }
  }
}

.point-list-wrapper .point-list .point-list-aside.point-list-warning:after {
  background-color: $link-color;
}

#search-modal {
  .text-primary {
    color: $link-color !important;
  }
}

.squares-image-template .box .title {
  color: $primary-text;
}

.faq-tree {
  .accordion {
    .accordion-header {
      .accordion-button[data-bs-toggle='collapse'][aria-expanded='false'] {
        color: $primary-text !important;
      }

      .accordion-button {
        color: $primary-text;

        &:after {
          content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 24 24' %3E%3Cg%3E%3Cpath fill='#{url-friendly-color($primary-text)}' d='M12,10.3l4.8,4.8c0.3,0.3,0.8,0.3,1.1,0c0.3-0.3,0.3-0.8,0-1c0,0,0,0,0,0l-4.8-4.8c-0.6-0.6-1.5-0.6-2.1,0L6.2,14c-0.3,0.3-0.3,0.8,0,1c0,0,0,0,0,0c0.3,0.3,0.8,0.3,1.1,0L12,10.3z'/%3E%3C/g%3E%3C/svg%3E");
        }
      }
    }
  }
}

.block.accordion
  .section.full-width
  .card
  .card-body
  .accordion-item.subblock-view
  .accordion-header {
  button {
    color: $link-color;
  }
}

.accordion-header {
  .accordion-button:after {
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 24 24' %3E%3Cg%3E%3Cpath fill='#{url-friendly-color($link-color)}' d='M12,10.3l4.8,4.8c0.3,0.3,0.8,0.3,1.1,0c0.3-0.3,0.3-0.8,0-1c0,0,0,0,0,0l-4.8-4.8c-0.6-0.6-1.5-0.6-2.1,0L6.2,14c-0.3,0.3-0.3,0.8,0,1c0,0,0,0,0,0c0.3,0.3,0.8,0.3,1.1,0L12,10.3z'/%3E%3C/g%3E%3C/svg%3E");
  }
}

.block.iconBlocks {
  .block-header {
    .title,
    .description {
      color: $primary-text;
    }
  }
  a.read-more .icon {
    fill: $gray-secondary;
  }
}

.prenotazioni-folder-view,
.prenotazione-view {
  .select-pill.text-primary {
    color: $link-color !important;
  }
}
.prenotazione-view {
  .steppers .steppers-header ul {
    li.active,
    li.confirmed {
      color: $link-color;
    }

    li.active::after {
      background: $link-color;
    }

    li.confirmed .icon {
      fill: $link-color;
    }
  }

  .prenotazioneDataEOra .bootstrap-select-wrapper .react-select__menu {
    .react-select__option {
      color: $link-color;
    }

    .react-select__option--is-selected {
      color: $tertiary-text;
    }
  }

  .radio-card.border-focus-primary.border-focus-primary {
    outline: 2px solid $link-color;
  }

  .form-check {
    [type='radio'] {
      &:checked + label {
        &::before {
          border-color: $link-color;
        }

        &::after {
          border-color: $link-color;
          background-color: $link-color;
        }
      }
    }
  }
}

.form-check {
  [type='radio'] {
    &:checked + label {
      &::before {
        border-color: $link-color;
      }

      &::after {
        border-color: $link-color;
        background-color: $link-color;
      }
    }
  }
  [type='checkbox'] {
    &:checked + label {
      &::after {
        border-color: $link-color;
        background-color: $link-color;
      }
    }
  }
}
